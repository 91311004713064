<template>
  <dog-dialog
    ref="dogDialog"
    title="设置型号"
    @loading-confirm="confirm"
    @closed="closed"
    width="900px"
  >
    <div class="setModelDialog">
      <div v-for="(data, index) in row.model" :key="index" class="modelItem">
        <div class="add_delete">
          <i class="el-icon-circle-plus" @click="addFormData(index)"></i>
          <i
            class="el-icon-remove"
            @click="deleteFormData(index)"
            v-if="row.model.length > 1"
          ></i>
        </div>
        <dog-form
          ref="dogForm"
          :form-data="data"
          :column-num="3"
          :col-rule="(row) => row.col || 8"
          :form-options="formOptions"
        ></dog-form>
      </div>
    </div>
  </dog-dialog>
</template>

<script>
  import productService from '@/api/service/product.js';
  import singleImgUpload from '@/components/singleImgUpload.vue';
  import Sortable from 'sortablejs';

  export default {
    name: 'setModelDialog',
    data() {
      return {
        row: {
          model: [{}]
        },
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '型号',
              prop: 'name',
              rules: [this.$formRules.required('请输入型号')]
            }
          },
          {
            type: 'el-input-number',
            formItem: {
              label: '价格',
              prop: 'price',
              rules: [this.$formRules.required('请输入价格')]
            },
            attrs: {
              // default: 1,
              min: 0.01,
              step: 0.01,
              stepStrictly: true
            }
          },
          {
            type: 'el-input-number',
            formItem: {
              label: '原价',
              prop: 'oldPrice'
            },
            attrs: {
              // default: 1,
              min: 0.01,
              step: 0.01,
              stepStrictly: true
            }
          },
          {
            type: 'custom',
            formItem: {
              prop: 'img',
              label: '图片'
            },
            component: singleImgUpload,
            col: 16
          },
          {
            type: 'switch',
            formItem: {
              label: '是否启用',
              prop: 'enable',
              rules: []
            },
            attrs: {
              default: true
            }
          }
        ],
        productId: null
      };
    },
    methods: {
      openFrame(productId) {
        this.productId = productId;
        this.$refs.dogDialog.open();
        productService.getProductModel({ productId }).then((res) => {
          this.row.model =
            res.length > 0
              ? res.map((item) => {
                  return {
                    ...item,
                    enable: this.$methods.stringNumToBoolean(item.enable)
                  };
                })
              : [{}];
          this.dropReady();
        });
      },
      addFormData(index) {
        this.row.model.splice(index + 1, 0, {});
        this.dropReady();
      },
      deleteFormData(index) {
        this.row.model.splice(index, 1);
        this.dropReady();
      },
      confirm(done) {
        const formList = [...this.$refs.dogForm];
        Promise.all(
          formList.map((item) => {
            return item.validate();
          })
        )
          .then(() => {
            return productService.setProductModel({
              ...this.row,
              productId: this.productId,
              model: this.row.model.map((item) => {
                return {
                  ...item,
                  enable: this.$methods.booleanToStringNum(item.enable)
                };
              })
            });
          })
          .then(() => {
            this.close();
            this.$emit('confirm');
          })
          .finally(done);
      },
      dropReady() {
        this.$nextTick(() => {
          this.formDrop();
        });
      },
      formDrop() {
        const formList = this.$el.querySelector('.setModelDialog');
        Sortable.create(formList, {
          animation: 180,
          onEnd: (evt) => {
            const model = [...this.row.model];
            this.row.model = [];
            this.$nextTick(() => {
              const currRow = model.splice(evt.oldIndex, 1)[0];
              model.splice(evt.newIndex, 0, currRow);
              this.row.model = model;
            });
          }
        });
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.row = { model: [{}] };
        this.productId = null;
      }
    }
  };
</script>

<style lang="less" scoped>
  .add_delete {
    margin-bottom: 10px;
    text-align: right;
  }
  .el-icon-circle-plus {
    font-size: 25px;
    color: #0080ff;
    cursor: pointer;
  }
  .el-icon-remove {
    margin-left: 5px;
    font-size: 25px;
    color: red;
    cursor: pointer;
  }
  .modelItem {
    border: 1px dashed #c0ccda;
    margin-bottom: 10px;
  }
</style>
